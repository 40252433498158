import api from '../../../../app/api';
import { HintTimeMessage } from './IOptions';  

class OptionsService {

  saveHintTimeMessage(hintTimeMessage: HintTimeMessage) {

    return api.put('/ttrivia/config/hintTime', hintTimeMessage)

  }
}

const optionsService = new OptionsService();

export default optionsService;