import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useState } from 'react';
import { Options } from './options/options';

export function Preferences() {
  const [tabKey, setTabKey] = useState('options');
  return (
    <>
      <Tabs
      defaultActiveKey="profile"
      id="configuration-tabs"
      activeKey={tabKey}
      className="mb-3 configuration-tabs"
    >
      <Tab eventKey="options" title="Options">
        <Options />
      </Tab>
      <Tab eventKey="placeholder" title="Placeholder" disabled>
        <></>
      </Tab>
      <Tab eventKey="placeholder" title="Placeholder" disabled>
        <></>
      </Tab>
      <Tab eventKey="placeholder" title="Placeholder" disabled>
        <></>
      </Tab>
    </Tabs>
    </>
  );
}