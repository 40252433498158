import { useEffect, useState } from "react";
import { Category, Question } from "../IQuestion";
import { InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { deleteQuestion, saveQuestions } from '../question.action';
import { useAppDispatch } from "../../../../app/store";
import { fetchQuestionAmount, fetchQuestions, selectQuestions } from '../questionsSlice';
import { useSelector } from "react-redux";
import { QuestionPagination } from "./pagination";
import { fetchCategories, selectCategories } from "../categoriesSlice";
import { userDataFunction } from "../../userDataSlice";

export function QuestionList() {
  const dispatch = useAppDispatch();

  const questions = useSelector(selectQuestions);
  const categories = useSelector(selectCategories);
  const userData = useSelector(userDataFunction);
  const [formCategories, setFormCategories] = useState<Category[]>([]);
  const [editedQuestions, setEditedQuestions] = useState<Question[]>([]);

  const handleEditQuestion = (
    questionId: number,
    field: "question" | "answer" | "category" | "reviewed",
    value: string | boolean
  ) => {
    const updatedQuestions = editedQuestions.map((question) => {
      if (question.id === questionId) {
        return { ...question, [field]: value };
      } else {
        return question;
      }
    });
    setEditedQuestions(updatedQuestions);
  };
  const handleSaveQuestions = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedQuestions = questions.map((question) => {
      const editedQuestion = editedQuestions.find((q) => q.id === question.id);
      if (editedQuestion) {
        return { ...question, ...editedQuestion };
      } else {
        return question;
      }
    });
    await dispatch(saveQuestions(updatedQuestions));
    dispatch(fetchQuestions());
    dispatch(fetchQuestionAmount());
  };

  const handleDeleteQuestion = async (questionId: number) => {
    await dispatch(deleteQuestion(questionId));
    dispatch(fetchQuestions());
    dispatch(fetchQuestionAmount());
  }

  useEffect(() => {
    dispatch(fetchCategories());
    setEditedQuestions(questions);
  }, [dispatch, questions]);

  useEffect(() => {
    setFormCategories(categories);
  }, [categories]);

  return (
    <>
      <div className="question-heading">Edit questions</div>
      <Form className="question-form" onSubmit={handleSaveQuestions}>
        <Row className="question-row">
          <Col xs={userData?.group === "admin" ? 5 : 6}>
            <Form.Label>
              Questions
            </Form.Label>
          </Col>
          <Col xs={userData?.group === "admin" ? 2 : 2}>
            <Form.Label>Category</Form.Label>
          </Col>
          <Col xs={userData?.group === "admin" ? 3 : 3}>
            <Form.Label>
              Answers
            </Form.Label>
          </Col>
          <Col xs={userData?.group === "admin" ? 1 : 0}>
              {userData?.group === "admin" ? "Reviewed" : <></>}
          </Col>
        </Row>
        {Array.isArray(editedQuestions) && editedQuestions.map(question => (
        <Row className="question-row" key={question.id}>
          <Col xs={userData?.group === "admin" ? 5 : 6}  className="question-col">
            <Form.Control
              key={question.id}
              value={question.question}
              onChange={(e) => handleEditQuestion(question.id, "question", e.target.value)}
            />
          </Col>
          
          <Col xs={userData?.group === "admin" ? 2 : 2} className="question-col">
            <Form.Select
              key={question.id}
              value={question.category}
              onChange={(e) =>
                handleEditQuestion(question.id, "category", e.target.value)
              }
            >
              {Array.isArray(formCategories) &&
                formCategories.map((category) => (
                  <option value={category.category} key={`${category.category}-${question.id}`}>
                    {category.category}
                  </option>
              ))}
            </Form.Select>
          </Col>
          
          <Col xs={userData?.group === "admin" ? 3 : 3} className="question-col">
            <InputGroup>
              <Form.Control 
                key={question.id}
                value={question.answer}
                onChange={(e) => handleEditQuestion(question.id, "answer", e.target.value)}
              />
            </InputGroup>
          </Col>
          {userData?.group === "admin" && (
            <Col xs={1} className="checkbox-question-col d-flex align-items-center">
              <Form.Check
                key={question.id}
                type="checkbox"
                checked={question.reviewed ?? false}
                onChange={(e) =>
                  handleEditQuestion(question.id, "reviewed", e.target.checked)
                }
              />
            </Col>
          )}
          <Col xs={1} className="question-col">
            <Button variant="danger" onClick={() => handleDeleteQuestion(question.id)}>
              Delete
            </Button>
          </Col>
        </Row>
      ))}
        <div className="question-save-button">
          <Row>
            <Col xs={5}>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </div>
        <QuestionPagination />
      </Form>
    </>
  );
}