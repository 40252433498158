import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Category } from './IQuestion'
import { AppThunk, RootState } from '../../../app/store';
import api from '../../../app/api';

interface State {
  categories: Category[];
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  categories: [],
  loading: false,
  error: null,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setCategories(state, action: PayloadAction<Category[]>) {
      state.categories = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const fetchCategories = (): AppThunk<void> => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/questions/categories`);
    dispatch(setCategories(response.data));
  } catch (error) {
    dispatch(setError((error as Error).message));
  } finally {
    dispatch(setLoading(false));
  }
};



export const { setLoading, setCategories, setError } = categoriesSlice.actions;
export const selectCategories = (state: RootState) => state.categories.categories;
export const selectLoading = (state: RootState) => state.categories.loading;
export const selectError = (state: RootState) => state.categories.error;

export default categoriesSlice.reducer;