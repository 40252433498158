import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface State {
  userInitFunction: boolean;
}

const initialState: State = {
  userInitFunction: false,
};

const userInitSlice = createSlice({
  name: 'userInitSlice',
  initialState,
  reducers: {
    setUserInitFunction(state) {
      state.userInitFunction = true;
    },
  },
});

export const { setUserInitFunction } = userInitSlice.actions;
export const userInitFunction = (state: RootState) => state.userInit.userInitFunction;
export default userInitSlice.reducer;