import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle network errors
    if (!error.response) {
      console.error(error.message);
      return Promise.reject(error);
    }

    // Handle server errors
    if (error.response.status === 401) {
      console.error(error.response.data);
      return Promise.reject(error);
    }

    console.error(error.response.data);
    return Promise.reject(error);
  }
);

export default instance;