import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userInitReducer from '../features/private/userInitSlice'
import authInitReducer from '../authInitSlice'
import userDataReducer from '../features/private/userDataSlice'
import questionsReducer from '../features/private/questions/questionsSlice'
import notificationsReducer from '../features/private/configuration/notifications/notificationsSlice'
import optionsReducer from '../features/private/preferences/options/optionsSlice'
import categoriesReducer from '../features/private/questions/categoriesSlice'
import modStatusReducer from '../features/private/status/components/statusSlice'
import modInitReducer from '../features/private/status/components/modInitSlice'
import botStatusReducer from '../features/private/status/components/botStatusSlice'
import botStartReducer from '../features/private/status/components/botStartSlice'
import botStatusInitReducer from '../features/private/status/components/botStatusInitSlice'
import authDataReducer from '../authSlice'
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';

export const store = configureStore({
  reducer: {
    userInit: userInitReducer,
    authInit: authInitReducer,
    userData: userDataReducer,
    authData: authDataReducer,
    questions: questionsReducer,
    notifications: notificationsReducer,
    options: optionsReducer,
    categories: categoriesReducer,
    botModStatus: modStatusReducer,
    botStatus: botStatusReducer,
    botStart: botStartReducer,
    modInit: modInitReducer,
    botStatusInitSlice: botStatusInitReducer
  }
});

export type AppDispatch = ThunkDispatch<RootState, undefined, Action>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
