import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Pagination from "react-bootstrap/Pagination";
import Form from "react-bootstrap/Form";
import {
  fetchQuestions,
  fetchQuestionAmount,
  selectCurrentPage,
  selectPageSize,
  selectQuestionAmount,
  setCurrentPage,
  setPageSize,
} from '../questionsSlice';
import { useAppDispatch } from "../../../../app/store";

export function QuestionPagination() {
  const dispatch = useAppDispatch();
  const pageSize = useSelector(selectPageSize);
  const questionAmount = useSelector(selectQuestionAmount);
  const currentPage = useSelector(selectCurrentPage);
  const [paginationButtons, setPaginationButtons] = useState<JSX.Element[]>([]);

  const handlePageChange = (pageNumber: number) => {
    dispatch(setCurrentPage(pageNumber));
  };

  useEffect(() => {
    dispatch(fetchQuestions());
    dispatch(fetchQuestionAmount());
  }, [dispatch, pageSize, currentPage]);

  useEffect(() => {
    const totalPages = Math.ceil(questionAmount / pageSize);
    const buttons: JSX.Element[] = [];

    if (totalPages <= 1) {
      setPaginationButtons([]);
      return;
    }

    // always show first page
    buttons.push(
      <Pagination.Item
        key={1}
        active={1 === currentPage}
        onClick={() => handlePageChange(1)}
      >
        {1}
      </Pagination.Item>
    );

    // show "..." if there are more than 5 pages
    if (totalPages > 5) {
      buttons.push(<Pagination.Ellipsis key={"ellipsis1"} />);
    }

    // show current page and adjacent pages
    for (
      let i = Math.max(2, currentPage - 2);
      i <= Math.min(currentPage + 2, totalPages - 1);
      i++
    ) {
      buttons.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // show "..." if there are more than 5 pages
    if (totalPages > 5) {
      buttons.push(<Pagination.Ellipsis key={"ellipsis2"} />);
    }

    // always show last page
    buttons.push(
      <Pagination.Item
        key={totalPages}
        active={totalPages === currentPage}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </Pagination.Item>
    );

    setPaginationButtons(buttons);
  }, [currentPage, pageSize, questionAmount]);

  return (
    <>
      <div className="d-flex justify-content-center">
        <Form.Control
          as="select"
          className="text-center question-amount-form"
          value={pageSize}
          onChange={(e) => dispatch(setPageSize(Number(e.target.value)))}
        >
          <option value={5}>5</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={75}>75</option>
          <option value={100}>100</option>
        </Form.Control>
      </div>
      <div className="question-pagination-div d-flex justify-content-center">
        <Pagination className="question-pagination">
          {paginationButtons}
        </Pagination>
      </div>
    </>
  );
}