import api from '../../../../../src/app/api';
import { NotificationMessage } from './INotifications';  

class NotificationService {

  postNotificationMessage(notificationMessage: String) {

    return api.post('/notification', { text: notificationMessage});

  }

  deleteNotificationMessage(notificationMessageId: number) {

    return api.delete(`/notification/${notificationMessageId}`);

  }

  saveNotificationMessage(notificationMessage: NotificationMessage) {

    return api.put('/notification', notificationMessage)

  }
}

const notificationService = new NotificationService();

export default notificationService;