import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';

interface State {
  botStatusInitFunction: boolean;
}

const initialState: State = {
  botStatusInitFunction: false,
};

const botStatusInitSlice = createSlice({
  name: 'modInitSlice',
  initialState,
  reducers: {
    setBotStatusInitFunction(state) {
      state.botStatusInitFunction = true;
    },
  },
});

export const { setBotStatusInitFunction } = botStatusInitSlice.actions;
export const botStatusInitFunction = (state: RootState) => state.botStatusInitSlice.botStatusInitFunction;
export default botStatusInitSlice.reducer;