import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HintTimeMessage } from './IOptions'
import { AppThunk, RootState } from '../../../../app/store';
import api from '../../../../app/api';

interface State {
  hintTimeMessage: HintTimeMessage;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  hintTimeMessage: {time: 30},
  loading: false,
  error: null,
};

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setHintTimeMessage(state, action: PayloadAction<HintTimeMessage>) {
      state.hintTimeMessage = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const fetchHintTimeMessage = (): AppThunk<void> => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/ttrivia/config/hintTime`);
    const hintTimeMessage: HintTimeMessage = response.data;
    dispatch(setHintTimeMessage(hintTimeMessage));
  } catch (error) {
    dispatch(setError((error as Error).message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const { setLoading, setHintTimeMessage, setError } = optionsSlice.actions;
export const selectHintTimeMessage = (state: RootState) => state.options.hintTimeMessage;
export const selectLoading = (state: RootState) => state.options.loading;
export const selectError = (state: RootState) => state.options.error;

export default optionsSlice.reducer;