import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { AppThunk, RootState } from '../../../../app/store';

interface State {
  isBotRunning: boolean | null;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  isBotRunning: null,
  loading: false,
  error: null,
};

const botStatusSlice = createSlice({
  name: 'botStatus',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setBotStatus(state, action: PayloadAction<boolean>) {
      state.isBotRunning = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const fetchBotStatus = (): AppThunk<void> => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get('/ttrivia/status');
    const isBotRunning: boolean = response.data;
    dispatch(setBotStatus(isBotRunning));
  } catch (error) {
    dispatch(setError((error as Error).message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const { setLoading, setBotStatus, setError } = botStatusSlice.actions;
export const selectBotStatus = (state: RootState) => state.botStatus.isBotRunning;
export const selectBotStatusLoading = (state: RootState) => state.botStatus.loading;
export const selectBotStatusError = (state: RootState) => state.botStatus.error;

export default botStatusSlice.reducer;