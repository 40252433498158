import { Link } from "react-router-dom";
import { Navbar, Nav, Form, Container, Button, NavDropdown } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { userDataFunction } from "../../private/userDataSlice";

const handleLoginClick = () => {
  if (process.env.REACT_APP_BACKEND_URL) {
    window.location.href = process.env.REACT_APP_BACKEND_URL+"/login";
  } else {
    console.error('REACT_APP_BACKEND_URL environment variable is not defined');
  }
};

const handleLogoutClick = () => {
  if (process.env.REACT_APP_BACKEND_URL) {
    window.location.href = process.env.REACT_APP_BACKEND_URL+"/logout";
  } else {
    console.error('REACT_APP_BACKEND_URL environment variable is not defined');
  }
};

interface TopbarProps {
  isLoggedIn: boolean;
}

export function Topbar({ isLoggedIn }: TopbarProps) {
  const userData = useSelector(userDataFunction);
  return (
    <Navbar className="topbar">
      <Container>
        <Link to="/">
          <Navbar.Brand>
            <img
              alt=""
              src="/logo.png"
              width="90"
              height="35"
              className="d-inline-block align-center"
            />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '250px' }}
            navbarScroll
          >
            {isLoggedIn ? (
              <></>
            ) : (
              <>
                <Link to="/" className="topbar-links">Home</Link>
                <Link to="/features" className="topbar-links">Features</Link>
                <Link to="/pricing" className="topbar-links">Pricing</Link>
              </>
            )}
          </Nav>
          <Form className="d-flex">
          {isLoggedIn ? (
            <>
              <Navbar.Text className="topbar-greeting">
                Hello, {userData?.displayName}
              </Navbar.Text>
              <NavDropdown
                id="nav-dropdown-dark-example"
                align="end"
                title=
                  {
                    <img
                      alt=""
                      src={userData?.profileImageUrl}
                      width="40"
                      height="40"
                      className="d-inline-block align-center navbar-profile-image"
                    />
                  }
                menuVariant="dark"
              >
                <NavDropdown.Item href="#profile">Profile</NavDropdown.Item>
                <NavDropdown.Item href="#placeholder">Placeholder</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogoutClick}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </>
          ) : (
            <Button className="twitch-color" onClick={handleLoginClick}>Login with Twitch</Button>
          )}
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}