import { QuestionList } from './components/questionList';
import { QuestionForm } from './components/addQuestionForm';
import { QuestionsCSVForm } from './components/addQuestionsCSV';
import { useSelector } from 'react-redux';
import { userDataFunction } from '../userDataSlice';

export function Questions() {
  const userData = useSelector(userDataFunction);
  return (
    <>
      <QuestionForm />
      {userData?.group === "admin" ? (
        <QuestionsCSVForm />
      ): (
        <></>
      )}
      <QuestionList />
    </>
  );
}