import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';

interface State {
  modInitFunction: boolean;
}

const initialState: State = {
  modInitFunction: false,
};

const modInitSlice = createSlice({
  name: 'modInitSlice',
  initialState,
  reducers: {
    setModInitFunction(state) {
      state.modInitFunction = true;
    },
  },
});

export const { setModInitFunction } = modInitSlice.actions;
export const modInitFunction = (state: RootState) => state.modInit.modInitFunction;
export default modInitSlice.reducer;