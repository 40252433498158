import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './app/store';

interface State {
  authInitFunction: boolean;
}

const initialState: State = {
  authInitFunction: false,
};

const authInitSlice = createSlice({
  name: 'authInitSlice',
  initialState,
  reducers: {
    setAuthInitFunction(state) {
      state.authInitFunction = true;
    },
  },
});

export const { setAuthInitFunction } = authInitSlice.actions;
export const authInitFunction = (state: RootState) => state.authInit.authInitFunction;
export default authInitSlice.reducer;