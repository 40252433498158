import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import api from '../../../src/app/api';

interface User {
  displayName: string;
  profileImageUrl: string;
  group: string;
}

interface State {
  userDataFunction: User | null;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  userDataFunction: null,
  loading: false,
  error: null,
};

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setUserDataFunction(state, action: PayloadAction<User>) {
      state.userDataFunction = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const fetchUser = (): AppThunk<void> => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get('/user');
    const userData: User = {
      displayName: response.data.display_name,
      profileImageUrl: response.data.profile_image_url,
      group: response.data.user_group
    };
    dispatch(setUserDataFunction(userData));
  } catch (error) {
    dispatch(setError((error as Error).message));
  }
  finally {
    dispatch(setLoading(false));
  }
};

export const { setLoading, setUserDataFunction, setError } = userDataSlice.actions;
export const userDataFunction = (state: RootState) => state.userData.userDataFunction;
export const selectUserDataStatusLoading = (state: RootState) => state.userData.loading;
export const selectUserDataError = (state: RootState) => state.userData.error;
export default userDataSlice.reducer;