import { Table } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

export function Overview() {
  return (
    <>
      <Row className="global-notification-row">
        <Col xs={12} md={6} className="overview-table">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Command</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>!TTrivia</td>
                <td>Checks if the bot is active and greets the user.</td>
              </tr>
              <tr>
                <td>!TTrivia start</td>
                <td>Starts a new TTrivia game.</td>
              </tr>
              <tr>
                <td>!TTrivia stop</td>
                <td>Stops the current TTrivia game.</td>
              </tr>
              <tr>
                <td>!TTrivia rank</td>
                <td>Shows your personal rank and score.</td>
              </tr>
              <tr>
                <td>!TTrivia top</td>
                <td>Shows the top 5 players on the leaderboard.</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}