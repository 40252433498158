import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useState } from 'react';
import { Notifications } from './notifications/notifications';

export function Configuration() {
  const [tabKey, setTabKey] = useState('notifications');
  return (
    <>
      <Tabs
      defaultActiveKey="profile"
      id="configuration-tabs"
      activeKey={tabKey}
      className="mb-3 configuration-tabs"
    >
      <Tab eventKey="notifications" title="Notifications">
        <Notifications />
      </Tab>
      <Tab eventKey="placeholder" title="Placeholder" disabled>
        <></>
      </Tab>
      <Tab eventKey="placeholder" title="Placeholder" disabled>
        <></>
      </Tab>
      <Tab eventKey="placeholder" title="Placeholder" disabled>
        <></>
      </Tab>
    </Tabs>
    </>
  );
}