import { ModeratorCard } from './components/moderatorCard';
import { InstanceCard } from './components/instanceCard';

export function Status() {
  return (
    <>
      <ModeratorCard />
      <InstanceCard />
    </>
  );
}