import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from '../../features/public/home/home';
import { Pricing } from '../../features/public/pricing/pricing';
import { Features } from '../../features/public/features/features';

export function Public() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/features" element={<Features />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}