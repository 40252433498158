import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Question } from './IQuestion'
import { AppThunk, RootState } from '../../../app/store';
import api from '../../../../src/app/api';

interface State {
  questions: Question[];
  questionAmount: number;
  loading: boolean;
  error: string | null;
  pageSize: number;
  currentPage: number;
}

const initialState: State = {
  questions: [],
  questionAmount: 5,
  loading: false,
  error: null,
  pageSize: 5,
  currentPage: 1,
};

const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setQuestions(state, action: PayloadAction<Question[]>) {
      state.questions = action.payload;
    },
    setQuestionAmount(state, action: PayloadAction<number>) {
      state.questionAmount = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
});

export const fetchQuestions = (): AppThunk<void> => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const { pageSize, currentPage } = getState().questions;
    const response = await api.get(`/questions?pageSize=${pageSize}&currentPage=${currentPage}`);
    const questions: Question[] = response.data;
    dispatch(setQuestions(questions));
  } catch (error) {
    dispatch(setError((error as Error).message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchQuestionAmount = (): AppThunk<void> => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/questionAmount`);
    dispatch(setQuestionAmount(response.data));
  } catch (error) {
    dispatch(setError((error as Error).message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const { setLoading, setQuestions, setQuestionAmount, setError, setPageSize, setCurrentPage } = questionsSlice.actions;
export const selectQuestions = (state: RootState) => state.questions.questions;
export const selectQuestionAmount = (state: RootState) => state.questions.questionAmount;
export const selectLoading = (state: RootState) => state.questions.loading;
export const selectError = (state: RootState) => state.questions.error;
export const selectPageSize = (state: RootState) => state.questions.pageSize;
export const selectCurrentPage = (state: RootState) => state.questions.currentPage;

export default questionsSlice.reducer;