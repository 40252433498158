import { Topbar } from './features/public/topbar/topbar';
import { Private } from './features/private/private';
import { Public } from './features/public/public';
import { useSelector } from 'react-redux';
import { useAppDispatch } from './app/store';
import { useEffect } from 'react';
import { checkAuth, checkAuthDataFunction } from './authSlice';
import { authInitFunction, setAuthInitFunction } from './authInitSlice';

export default function App() {
  const authInit = useSelector(authInitFunction);
  const dispatch = useAppDispatch();
  const authData = useSelector(checkAuthDataFunction);
  const isLoggedIn = authData?.loggedIn || false;

  useEffect(() => {
    if(!authInit) {
      dispatch(checkAuth());
      dispatch(setAuthInitFunction());
    }
  }, [authInit, dispatch]);

  return (
    <>
      <Topbar isLoggedIn={isLoggedIn} />
      {isLoggedIn ? (
        <Private />
      ) : (
        <Public />
      )}
    </>
  );
}