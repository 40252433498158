import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationMessage } from './INotifications'
import { AppThunk, RootState } from '../../../../app/store';
import api from '../../../../../src/app/api';

interface State {
  notificationMessages: NotificationMessage[];
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  notificationMessages: [],
  loading: false,
  error: null,
};

const notificationsSlice = createSlice({
  name: 'notificationMessages',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setNotificationMessages(state, action: PayloadAction<NotificationMessage[]>) {
      state.notificationMessages = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const fetchNotificationMessages = (): AppThunk<void> => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get(`/notification`);
    const notificationMessages: NotificationMessage[] = response.data;
    dispatch(setNotificationMessages(notificationMessages));
  } catch (error) {
    dispatch(setError((error as Error).message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const { setLoading, setNotificationMessages, setError } = notificationsSlice.actions;
export const selectNotificationMessages = (state: RootState) => state.notifications.notificationMessages;
export const selectLoading = (state: RootState) => state.notifications.loading;
export const selectError = (state: RootState) => state.notifications.error;

export default notificationsSlice.reducer;