import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './app/store';
import api from '../src/app/api';

interface Auth {
  loggedIn: boolean;
}

interface State {
  checkAuthDataFunction: Auth | null;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  checkAuthDataFunction: null,
  loading: false,
  error: null,
};

const checkAuthSlice = createSlice({
  name: 'checkAuth',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setCheckAuthDataFunction(state, action: PayloadAction<Auth>) {
      state.checkAuthDataFunction = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const checkAuth = (): AppThunk<void> => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get('/check-auth');
    const checkAuthData: Auth = {
      loggedIn: response.data,
    };
    dispatch(setCheckAuthDataFunction(checkAuthData));
  } catch (error) {
    dispatch(setError((error as Error).message));
  }
  finally {
    dispatch(setLoading(false));
  }
};

export const { setLoading, setCheckAuthDataFunction, setError } = checkAuthSlice.actions;
export const checkAuthDataFunction = (state: RootState) => state.authData.checkAuthDataFunction;
export const selectAuthDataStatusLoading = (state: RootState) => state.authData.loading;
export const selectAuthDataError = (state: RootState) => state.authData.error;
export default checkAuthSlice.reducer;