import { useEffect } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../app/store";
import { fetchBotStart, selectBotStartLoading } from "./botStartSlice";
import { botStatusInitFunction, setBotStatusInitFunction } from "./botStatusInitSlice";
import { fetchBotStatus, selectBotStatus, selectBotStatusLoading } from "./botStatusSlice";

export function InstanceCard() {
  const dispatch = useAppDispatch();
  const botStatusInit = useSelector(botStatusInitFunction);

  const botStatusLoading = useSelector(selectBotStatusLoading);

  const botStartLoading = useSelector(selectBotStartLoading);

  const isBotRunning = useSelector(selectBotStatus);

  useEffect(() => {
    if(!botStatusInit)
      dispatch(fetchBotStatus());
      dispatch(setBotStatusInitFunction());
  }, [dispatch, botStatusInit]);

  const handleClickBotStatusRefresh = async () => {
    dispatch(fetchBotStatus());
  }

  const handleClickBotStart = async () => {
    await dispatch(fetchBotStart());
  }

  return (
    <div className="status-moderator-card">
    <Card>
      <Card.Header as="h5">TTrivia Instance Status</Card.Header>
      <Card.Body>
        {botStatusLoading || botStartLoading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <Spinner animation="border" />
          </div>
        ) :
        (
          <>
          <Card.Title>Check the status of your TTrivia instance</Card.Title>
          {isBotRunning ? (
            <>
              <div className="status-moderator-top-line-card">Your TTrivia instance is running.</div>
              <div className="bottom-line-card">Try writing  <mark className="status-moderator-mark">!TTrivia</mark>  in your chat.</div>
            </>
          ) : (
            <>
              <div className="status-moderator-top-line-card">Your TTrivia instance is not running.</div>
              <div className="bottom-line-card">You can now start your instance.</div>
            </>
          )}
          {isBotRunning ? (
            <div className="status-moderator-reload-button">
              <Button variant="primary" disabled={botStatusLoading} onClick={handleClickBotStatusRefresh}>Refresh</Button>
            </div>
          ) : (
            <Row>
              <Col xs="auto">
                <div className="status-moderator-reload-button">
                  <Button variant="primary" disabled={botStatusLoading} onClick={handleClickBotStart}>Start</Button>
                </div>
              </Col>
              <Col>
                <div className="status-moderator-reload-button">
                  <Button variant="primary" disabled={botStatusLoading} onClick={handleClickBotStatusRefresh}>Refresh</Button>
                </div>
              </Col>
            </Row>
          )}
          </>
        )}
      </Card.Body>
    </Card>
  </div>
  );
}