import api from '../../../../src/app/api';
import { Question, QuestionData } from './IQuestion';  

class QuestionService {
  postCSVQuestions(csvData: string) {

    return api.post('/questions/csv', csvData);
  }

  postQuestion(questionData: QuestionData) {

    return api.post('/question', questionData);

  }

  deleteQuestion(questionId: number) {

    return api.delete(`/question/${questionId}`);

  }

  saveQuestions(questions: Array<Question>) {

    return api.put('/questions', questions)

  }
}

const questionService = new QuestionService();

export default questionService;