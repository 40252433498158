import { useEffect } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../app/store";
import { modInitFunction, setModInitFunction } from "./modInitSlice";
import { fetchModeratorStatus, selectModeratorLoading, selectModeratorStatus } from "./statusSlice";

export function ModeratorCard() {
  const dispatch = useAppDispatch();
  const modInit = useSelector(modInitFunction);
  const moderatorStatus = useSelector(selectModeratorStatus);
  const modLoading = useSelector(selectModeratorLoading);
  const botName = moderatorStatus?.botName || "...";
  const isBotModerator = moderatorStatus?.isModerator || false;

  const handleClickModeratorRefresh = async () => {
    dispatch(fetchModeratorStatus());
  }

  useEffect(() => {
    if(!modInit)
      dispatch(fetchModeratorStatus());
      dispatch(setModInitFunction());
  }, [dispatch, modInit]);

  return (
    <div className="status-moderator-card">
      <Card>
        <Card.Header as="h5">Moderator</Card.Header>
        <Card.Body>
            {modLoading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
                <Spinner animation="border" />
            </div>
            ) : (
            <>
                <Card.Title>Make the bot a moderator</Card.Title>
                {isBotModerator ? (
                    <>
                    <div className="status-moderator-top-line-card">All is good. You are set.</div>
                    <div className="bottom-line-card">The TTrivia bot  <mark className="status-moderator-mark">{botName}</mark>  is already a moderator in your twitch chat.</div>
                    </>
                ) : (
                    <>
                    <div className="status-moderator-top-line-card">In order to function the TTrivia bot has to be a moderator on your channel.</div>
                    <div className="bottom-line-card">Please enter  <mark className="status-moderator-mark">/mod {botName}</mark>  in your twitch chat.</div>
                    </>
                )}
                {isBotModerator ? (
                    <>
                    </>
                ) : (
                    <div className="status-moderator-reload-button">
                    <Button variant="primary" disabled={modLoading} onClick={handleClickModeratorRefresh}>Refresh</Button>
                    </div>
                )}
            </>
            )}
        </Card.Body>
      </Card>
    </div>
  );
}