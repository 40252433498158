import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';
import { useAppDispatch } from '../../../../../app/store';
import { useEffect, useState } from 'react';
import { saveNotificationMessage } from '../notification.action';
import { fetchNotificationMessages } from '../notificationsSlice';

interface EditNotificationModalProps {
  notificationMessageId: number;
  notificationMessageText: string;
  show: boolean;
  onHide: () => void;
}

export function EditNotificationModal(props: EditNotificationModalProps) {
  const dispatch = useAppDispatch();
  const [updatedNotificationMessage, setUpdatedNotificationMessage] = useState({ id: props.notificationMessageId, text: props.notificationMessageText });

  useEffect(() => {
    setUpdatedNotificationMessage({ id: props.notificationMessageId, text: props.notificationMessageText });
  }, [props.notificationMessageId, props.notificationMessageText]);

  const handleEditNotificationMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await dispatch(saveNotificationMessage(updatedNotificationMessage));
    await dispatch(fetchNotificationMessages());
    props.onHide();
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleEditNotificationMessage}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Notification:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            placeholder="Enter updated notification message."
            as="textarea"
            value={updatedNotificationMessage.text}
            rows={6}
            maxLength={250}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setUpdatedNotificationMessage({ id: props.notificationMessageId, text: e.target.value })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
          <Button type="submit">Save Changes</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}