import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useAppDispatch } from '../../../../app/store';
import { fetchHintTimeMessage, selectHintTimeMessage } from './optionsSlice';
import { useEffect, useState } from 'react';
import { HintTimeMessage } from './IOptions';
import { saveHintTime } from './options.action';

export function Options() {
  const dispatch = useAppDispatch();
  const hintTime = useSelector(selectHintTimeMessage);
  const [selectedHintTime, setSelectedHintTime] = useState<number>(hintTime.time);
  const hintTimeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120];

  const handleSaveHintTime = () => {
    const hintTimeMessage: HintTimeMessage = { time: selectedHintTime };
    dispatch(saveHintTime(hintTimeMessage));
  }

  useEffect(() => {
    dispatch(fetchHintTimeMessage());
  }, [dispatch]);

  useEffect(() => {
    setSelectedHintTime(hintTime.time);
  }, [hintTime]);

  return (
    <>
      <div className="question-heading">Bot hint timer.</div>
      <Row className="global-notification-row">
          <Col xs={2} className="options-card">
            <Card className="options-card">
              <Card.Body>
                <Card.Text>
                  This setting enables you to personalize the duration of time that must elapse without a correct answer before the bot sends a hint to your Twitch channel during TTrivia. 
                  You can adjust this setting to your preference, and the bot will automatically update for the next round of TTrivia.
                </Card.Text>
              </Card.Body>
              <Row>
                <Col className="options-hint-dropdown">
                <Form.Select value = {selectedHintTime} onChange={(e) => setSelectedHintTime(Number(e.target.value))}>
                  {hintTimeOptions.map((time) => (
                    <option key={time} value={time}>
                      {time} s
                    </option>
                  ))}
                </Form.Select>
                </Col>
                <Col className="options-hint-button">
                  <Button variant="primary" onClick={handleSaveHintTime}>Save</Button>
                </Col>
              </Row>
            </Card>
          </Col>
      </Row>
    </>
  );
}