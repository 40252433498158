import { InputGroup } from 'react-bootstrap';
import { useState } from "react";
import { fetchQuestionAmount, fetchQuestions } from "../questionsSlice";
import { useAppDispatch } from "../../../../app/store";
import { postCSVQuestions } from "../question.action";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { selectCategories } from '../categoriesSlice';

export function QuestionsCSVForm() {
  const dispatch = useAppDispatch();
  const categories = useSelector(selectCategories);
  const [text, setText] = useState('');
  const [csvModalShow, setCSVModalShow] = useState(false);
  const [categoriesModalShow, setCategoriesModalShow] = useState(false);

  const handleAddCSVQuestions = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await dispatch(postCSVQuestions(text));
    setText('');
    dispatch(fetchQuestions());
    dispatch(fetchQuestionAmount());
  }

  function CSVInfoModal(props: any) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            What is CSV?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Comma-separated values</h4>
          <div>Your question number 1, Answer to your question number 1</div>
          <div>Setting a category right here?, General Knowledge, "That works, great!"</div>
          <div>"Your question number 2, with a comma?", "The answer to your question number 2 can have one, too"</div>
          <div>Your question number 3, Answer to your question number 3</div>
          <div>"Your question number 4, with a comma?", "The answer to your question number 4 can have one, too"</div>
          <div>...</div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function CategoriesInfoModal(props: any) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Categories:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Valid categories:</h6>
          <h6>{categories.map((category) => category.category).join(", ")}</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return(
    <>
      <CSVInfoModal
        show={csvModalShow}
        onHide={() => setCSVModalShow(false)}
      />
      <CategoriesInfoModal
        show={categoriesModalShow}
        onHide={() => setCategoriesModalShow(false)}
      />
      <Row>
        <Col xs="auto">
          <div className="question-heading">Add questions in CSV format</div>
        </Col>
        <Col xs="auto">
          <div className="csv-questions-button">
            <Button variant="info" onClick={() => setCSVModalShow(true)}>
              CSV?
            </Button>
          </div>
        </Col>
        <Col xs="auto">
          <div className="csv-questions-button">
            <Button variant="info" onClick={() => setCategoriesModalShow(true)}>
              Categories?
            </Button>
          </div>
        </Col>
      </Row>
      <Form className="question-form" onSubmit={handleAddCSVQuestions}>
        <Row className="question-row">
          <Col xs={11}>
            <Form.Label>
              Multiple questions and answers in CSV format
            </Form.Label>
            <InputGroup>
              <Form.Control
                as="textarea"
                placeholder="Enter your questions and answers in CSV format"
                type="text"
                id="question"
                value={text}
                rows={6}
                onChange={(e) => setText(e.target.value)}
              />
            </InputGroup>
          </Col>
        </Row>
        <div className="csv-questions-save-button">
          <Button variant="primary" type="submit">
            Add
          </Button>
        </div>
      </Form>
    </>
  );
}