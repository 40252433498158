import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { AppThunk, RootState } from '../../../../app/store';
import { setBotStatus } from './botStatusSlice';

interface State {
  didBotStart: boolean | null;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  didBotStart: null,
  loading: false,
  error: null,
};

const botStartSlice = createSlice({
  name: 'botStart',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setBotStart(state, action: PayloadAction<boolean>) {
      state.didBotStart = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const fetchBotStart = (): AppThunk<void> => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get('/ttrivia/start');
    const didBotStart: boolean = response.data;
    dispatch(setBotStart(didBotStart));
    // if bot starts successfully, update bot status
    if (didBotStart) {
      dispatch(setBotStatus(true));
    }
  } catch (error) {
    dispatch(setError((error as Error).message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const { setLoading, setBotStart, setError } = botStartSlice.actions;
export const selectBotStart = (state: RootState) => state.botStart.didBotStart;
export const selectBotStartLoading = (state: RootState) => state.botStart.loading;
export const selectBotStartError = (state: RootState) => state.botStart.error;

export default botStartSlice.reducer;