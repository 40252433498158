import { Action, Dispatch } from 'redux';
import { HintTimeMessage } from './IOptions';
import OptionsService from './options.service';
  
export const saveHintTime = (hintTimeMessage: HintTimeMessage) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await OptionsService.saveHintTimeMessage(hintTimeMessage);
    dispatch({
      type: 'PUT_HINTTIMEMESSAGE_SUCCESS',
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: 'PUT_HINTTIMEMESSAGE_FAILURE',
      payload: (error as Error).message
    });
  }
};