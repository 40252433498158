import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../../app/api';
import { AppThunk, RootState } from '../../../../app/store';

interface ModeratorStatus {
  isModerator: boolean;
  botName: string;
}

interface State {
  moderatorStatus: ModeratorStatus | null;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  moderatorStatus: null,
  loading: false,
  error: null,
};

const statusSlice = createSlice({
  name: 'botModerator',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setModeratorStatus(state, action: PayloadAction<ModeratorStatus>) {
      state.moderatorStatus = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const fetchModeratorStatus = (): AppThunk<void> => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await api.get('/moderator');
    const moderatorStatus: ModeratorStatus = response.data;
    dispatch(setModeratorStatus(moderatorStatus));
  } catch (error) {
    dispatch(setError((error as Error).message));
  } finally {
    dispatch(setLoading(false));
  }
};

export const { setLoading, setModeratorStatus, setError } = statusSlice.actions;
export const selectModeratorStatus = (state: RootState) => state.botModStatus.moderatorStatus;
export const selectModeratorLoading = (state: RootState) => state.botModStatus.loading;
export const selectModeratorError = (state: RootState) => state.botModStatus.error;

export default statusSlice.reducer;