import { Action, Dispatch } from 'redux';
import QuestionService from './question.service';
import { Question, QuestionData } from './IQuestion';
  
export const postCSVQuestions = (csvData: string) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await QuestionService.postCSVQuestions(csvData);
    dispatch({
      type: 'POST_CSVQUESTIONS_SUCCESS',
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: 'POST_CSVQUESTIONS_FAILURE',
      payload: (error as Error).message
    });
  }
};

export const postQuestion = (questionData: QuestionData) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await QuestionService.postQuestion(questionData);
    dispatch({
      type: 'POST_QUESTION_SUCCESS',
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: 'POST_QUESTION_FAILURE',
      payload: (error as Error).message
    });
  }
};

export const deleteQuestion = (questionId: number) => async (dispatch: Dispatch<Action>) => {
    try {
      const response = await QuestionService.deleteQuestion(questionId);
      dispatch({
        type: 'POST_QUESTION_SUCCESS',
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: 'POST_QUESTION_FAILURE',
        payload: (error as Error).message
      });
    }
  };

export const saveQuestions = (questions: Array<Question>) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await QuestionService.saveQuestions(questions);
    dispatch({
      type: 'POST_QUESTION_SUCCESS',
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: 'POST_QUESTION_FAILURE',
      payload: (error as Error).message
    });
  }
};