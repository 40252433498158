import { Navigate, Route, Routes } from 'react-router-dom';
import { Sidebar } from '../../features/private/sidebar/sidebar';
import { Overview } from '../../features/private/overview/overview';
import { Questions } from '../../features/private/questions/questions';
import { Placeholder } from '../../features/private/placeholder/placeholder';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setUserInitFunction, userInitFunction } from './userInitSlice';
import { fetchUser } from './userDataSlice';
import { useAppDispatch } from '../../app/hooks';
import { Status } from './status/status';
import { Configuration } from './configuration/configuration';
import { Options } from './preferences/options/options';
import { Preferences } from './preferences/preferences';

export function Private() {
  const userInit = useSelector(userInitFunction);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if (!userInit) {
      // Call your function here
      // ...
      dispatch(fetchUser());

      // Dispatch an action to update the userInitFunction value
      dispatch(setUserInitFunction());
    }
  }, [dispatch, userInit]);

    return (
      <Sidebar>
        <Routes>
          <Route path="/auth/">
          <Route path="overview" element={<Overview />} />
            <Route path="questions" element={<Questions />} />
            <Route path="status" element={<Status />} />
            <Route path="configuration" element={<Configuration />}/>
            <Route path="preferences" element={<Preferences />}/>
            <Route path="placeholder" element={<Placeholder />}/>
          </Route>
          <Route path="*" element={<Navigate to="/auth/overview" />} />
        </Routes>
      </Sidebar>
    );
  }