import { NavLink } from "react-router-dom";
import { Nav, Row, Col, Container } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { userDataFunction } from "../userDataSlice";

interface SidebarProps {
  children: React.ReactNode;
}

export function Sidebar({ children }: SidebarProps) {
  const userData = useSelector(userDataFunction);
  return (
    <Container fluid>
      <Row>
        <Col sm={4} md={2} className="sidebar">
          <Nav className="flex-column sidebar-container">
          <NavLink to="/auth/overview" 
            className={({ isActive }) =>
            isActive ? "sidebar-links sidebar-links-active" : "sidebar-links"
            }>
            Dashboard
          </NavLink>
          {userData?.group === "user" || userData?.group === "moderator" || userData?.group === "admin" ? (
          <NavLink to="/auth/status"
            className={({ isActive }) =>
            isActive ? "sidebar-links sidebar-links-active" : "sidebar-links"
            }>
            Bot Status
          </NavLink>
          ): (
            <></>
          )}
          {userData?.group === "moderator" || userData?.group === "admin" ? (
          <NavLink to="/auth/questions"
            className={({ isActive }) =>
            isActive ? "sidebar-links sidebar-links-active" : "sidebar-links"
            }>
            Question Bank
          </NavLink>
          ): (
            <></>
          )}
          {userData?.group === "admin" ? (
          <NavLink to="/auth/configuration" 
            className={({ isActive }) =>
            isActive ? "sidebar-links sidebar-links-active" : "sidebar-links"      
            }>
            Configuration
          </NavLink>
          ): (
            <></>
          )}
          {userData?.group === "user" || userData?.group === "moderator" || userData?.group === "admin" ? (
            <NavLink to="/auth/preferences" 
              className={({ isActive }) =>
              isActive ? "sidebar-links sidebar-links-active" : "sidebar-links"      
              }>
              Preferences
            </NavLink>
          ): (
            <></>
          )}
          </Nav>
        </Col>
        <Col sm={9} md={10} className="main-content">
          {children}
        </Col>
      </Row>
    </Container>
  );
}