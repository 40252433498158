import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/store';
import { deleteNotificationMessage, postNotificationMessage } from './notification.action';
import { fetchNotificationMessages, selectNotificationMessages } from './notificationsSlice';
import { NewNotificationModal } from './components/NewNotificationModal';
import { NotificationMessage } from './INotifications';
import { EditNotificationModal } from './components/EditNotificationModal';

export function Notifications() {
  const dispatch = useAppDispatch();
  const notificationMessages = useSelector(selectNotificationMessages);
  const [newNotificationText, setNewNotificationText] = useState('');
  const [newNotificationModalShow, setNewNotificationModalShow] = useState(false);
  const [editNotificationModalShow, setEditNotificationModalShow] = useState(false);
  const [selectedNotificationMessage, setSelectedNotificationMessage] = useState({ id: 0, text: '' });

  useEffect(() => {
    dispatch(fetchNotificationMessages());
  }, [dispatch]);


  const handleAddNewNotificationMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await dispatch(postNotificationMessage(newNotificationText));
    setNewNotificationText('');
    await dispatch(fetchNotificationMessages());
  }

  const handleDeleteNotificationMessage = async (notificationMessageid: number) => {
    await dispatch(deleteNotificationMessage(notificationMessageid));
    await dispatch(fetchNotificationMessages());
  }

  const handleEditNotificationMessage = (notificationMessage: NotificationMessage) => {
    setSelectedNotificationMessage(notificationMessage);
    setEditNotificationModalShow(true);
  }

  return (
    <>
      <NewNotificationModal
        newNotificationText={newNotificationText}
        handleAddNewNotificationMessage={handleAddNewNotificationMessage}
        setNewNotificationText={setNewNotificationText}
        show={newNotificationModalShow}
        onHide={() => setNewNotificationModalShow(false)}
      />
      <EditNotificationModal
        notificationMessageId={selectedNotificationMessage.id}
        notificationMessageText={selectedNotificationMessage.text}
        show={editNotificationModalShow}
        onHide={() => setEditNotificationModalShow(false)}
      />
      <div className="question-heading">Global notification messages.</div>
      <Row className="global-notification-row">
        {Array.isArray(notificationMessages) && notificationMessages.map(notificationMessage => (
          <Col xs={2} className="notification-card" key={notificationMessage.id}>
            <Card className="notification-card">
              <Card.Body>
                <Card.Text>
                  {notificationMessage.text}
                </Card.Text>
              </Card.Body>
              <Row>
                <Col className="global-notification-list-button d-flex justify-content-center">
                  <Button variant="primary" onClick={() => handleEditNotificationMessage(notificationMessage)}>Edit</Button>
                </Col>
                <Col className="global-notification-list-button d-flex justify-content-center">
                  <Button variant="danger" onClick={() => handleDeleteNotificationMessage(notificationMessage.id)}>Delete</Button>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="global-notification-button-row">
        <Col>
          <Button variant="primary" onClick={() => setNewNotificationModalShow(true)}>New Notification</Button>
        </Col>
      </Row>
    </>
  );
}