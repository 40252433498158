import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { postQuestion } from './../question.action';
import { fetchQuestionAmount, fetchQuestions } from './../questionsSlice';
import { InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { selectCategories } from '../categoriesSlice';
import { userDataFunction } from '../../userDataSlice';


export function QuestionForm() {
  const dispatch = useAppDispatch();
  const categories = useSelector(selectCategories);
  const userData = useSelector(userDataFunction);

  const [question, setQuestion] = useState('');
  const [category, setCategory] = useState('');
  const [answer, setAnswer] = useState('');
  const [reviewed, setReviewed] = useState(false);

  const handleAddQuestion = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await dispatch(postQuestion({ question, category, answer, reviewed }));
    setQuestion('');
    setCategory('');
    setAnswer('');
    dispatch(fetchQuestions());
    dispatch(fetchQuestionAmount());
  }

  return(
    <>
      <div className="question-heading">Add a question</div>
      <Form className="question-form" onSubmit={handleAddQuestion}>
        <Row className="question-row">
          <Col xs={userData?.group === "admin" ? 5 : 6}>
            <Form.Label>
              Question
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Enter the question"
                type="text"
                id="question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col xs={userData?.group === "admin" ? 2 : 2}>
            <Form.Label>
              Category
            </Form.Label>
            <InputGroup>
            <Form.Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            {Array.isArray(categories) &&
              categories.map((category) => (
                <option value={category.category} key={`${category.category}`}>
                  {category.category}
                </option>
              ))}
          </Form.Select>
            </InputGroup>
          </Col>
          <Col xs={userData?.group === "admin" ? 3 : 3}>
            <Form.Label>
              Answer
            </Form.Label>
            <InputGroup>
              <Form.Control 
                placeholder="Enter the answer" 
                type="text"
                id="answer"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </InputGroup>
          </Col>
          {userData?.group === "admin" && (
            <Col xs={1} className="question-col">
              <Form.Label>
                Reviewed
              </Form.Label>
              <Form.Check
                type="checkbox"
                onChange={(e) => setReviewed(e.target.checked)}
              />
            </Col>
          )}
          <Col xs={1}>
            <Button type="submit">
              Add
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}