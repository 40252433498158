import { Action, Dispatch } from 'redux';
import NotificationService from './notification.service';
import { NotificationMessage } from './INotifications';
  
export const postNotificationMessage = (notificationMessage: String) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await NotificationService.postNotificationMessage(notificationMessage);
    dispatch({
      type: 'POST_NOTIFICATIONMESSAGE_SUCCESS',
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: 'POST_NOTIFICATIONMESSAGE_FAILURE',
      payload: (error as Error).message
    });
  }
};

export const deleteNotificationMessage = (notificationMessageId: number) => async (dispatch: Dispatch<Action>) => {
    try {
      const response = await NotificationService.deleteNotificationMessage(notificationMessageId);
      dispatch({
        type: 'DELETE_NOTIFICATIONMESSAGE_SUCCESS',
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: 'DELETE_NOTIFICATIONMESSAGE_FAILURE',
        payload: (error as Error).message
      });
    }
  };

export const saveNotificationMessage = (notificationMessage: NotificationMessage) => async (dispatch: Dispatch<Action>) => {
  try {
    const response = await NotificationService.saveNotificationMessage(notificationMessage);
    dispatch({
      type: 'PUT_NOTIFICATIONMESSAGE_SUCCESS',
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: 'PUT_NOTIFICATIONMESSAGE_FAILURE',
      payload: (error as Error).message
    });
  }
};