import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';
import { useAppDispatch } from '../../../../../app/store';
import { useState } from 'react';
import { postNotificationMessage } from '../notification.action';
import { fetchNotificationMessages } from '../notificationsSlice';

interface NewNotificationModalProps {
  newNotificationText: string;
  handleAddNewNotificationMessage: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  setNewNotificationText: React.Dispatch<React.SetStateAction<string>>;
  show: boolean;
  onHide: () => void;
}

export function NewNotificationModal(props: NewNotificationModalProps) {
  const dispatch = useAppDispatch();
  const [newNotificationText, setNewNotificationText] = useState('');

  const handleAddNewNotificationMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await dispatch(postNotificationMessage(newNotificationText));
    setNewNotificationText('');
    dispatch(fetchNotificationMessages());
    props.onHide();
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleAddNewNotificationMessage}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            New Notification:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            placeholder="Enter a new notification message."
            as="textarea"
            value={newNotificationText}
            rows={6}
            maxLength={250}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNewNotificationText(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Add</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}